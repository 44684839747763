<template>
  <section class="background py-12 px-4" id="security">
    <v-container class="small-container">
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="12"
          class="d-flex align-center justify-center"
        >
          <Lottie class="lottie" path="about.json" />
        </v-col>
        <v-col cols="12" lg="8" md="8" sm="12" order="first">
          <h2 id="security" class="mt-2">{{ $t("security.title") }}</h2>
          <p class="about-us-text">
            {{ $t("security.text") }}
          </p>
          <p class="about-us-text">
            {{ $t("security.text-two") }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Lottie from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
  components: {
    Lottie,
  },
};
</script>

<style lang="scss" scoped></style>
