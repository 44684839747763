import Vue from "vue";
import Vuex from "vuex";
import { getValidatorData, getFETData } from "@/services/fetch.js";
import web3 from "web3";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    fetch: {
      price: 0,
      staked: 0,
      validatorAddress: "fetch1p3l46e2ajm8aql0fayjuqxz92mepcng4l6t2c5",
    },
  },
  mutations: {
    VALIDATOR_DATA(state, data) {
      state.fetch.staked = web3.utils.fromWei(data.tokens);
    },
    FETCH_PRICE(state, newPrice) {
      state.fetch.price = newPrice;
    },
  },
  actions: {
    async FETCH_VALIDATOR_DATA({ commit, state }) {
      const validatorData = await getValidatorData(
        state.fetch.validatorAddress
      );
      const fetchPrice = await getFETData();
      commit("FETCH_PRICE", fetchPrice);
      commit("VALIDATOR_DATA", validatorData);
    },
  },
  getters: {
    stakeWorth({ fetch }) {
      return fetch.price * fetch.staked;
    },
    fetchData: ({ fetch }) => fetch,
  },
  modules: {},
});
