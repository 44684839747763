<template>
  <div>
    <Banner />

    <div class="blockchains-section">
      <v-container class="small-container">
        <div class="d-flex justify-space-around flex-wrap">
          <img class="blockchain-logo" src="../assets/img/avalanche-logo.svg" />
          <img class="blockchain-logo" src="../assets/img/fetch-ai-logo.png" />
        </div>
      </v-container>
    </div>

    <AboutUs />

    <Security />

    <HowItWorks />

    <!-- <section class="section calculator-section">
      <div class="calculator-decoration">
        <div></div>
        <div class="circle-2"></div>
      </div>
      <v-container>
        <h2 class="text-center pb-8">{{ $t("calculator.title") }}</h2>
        <Calculator />
      </v-container>
    </section> -->

    <!-- <section id="stake" class="section stake-section backgroundMedium">
      <v-container>
        <h2 class="text-center">{{ $t("stake.staking-instructions") }}</h2>
      </v-container>
      <Steps />
    </section> -->

    <!-- <section class="section faq-section">
      <v-container class="small-container">
        <h2 class="text-center pb-8">{{ $t("faq.title") }}</h2>
        <FAQ />
      </v-container>
      <div class="faq-decoration">
        <div class="circle"></div>
        <div class="circle-2"></div>
      </div>
    </section> -->
  </div>
</template>
<script>
import Banner from "../components/Home/Banner.vue";
// import FAQ from "../components/Home/FAQ.vue";
import AboutUs from "../components/Home/AboutUs.vue";
import Security from "../components/Home/Security.vue";
import HowItWorks from "../components/Home/HowItWorks.vue";
// import Calculator from "../components/Home/Calculator.vue";
// import Steps from "../components/Stake/Steps.vue";

export default {
  name: "Home",
  components: {
    Banner,
    HowItWorks,
    // FAQ,
    AboutUs,
    Security,
    // Calculator,
    // Steps,
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style scoped lang="scss">
.section {
  .faq-decoration {
    .circle {
      position: absolute;
      width: 400px;
      height: 400px;
      border-radius: 100%;
      background: var(--v-primary-base);
      bottom: 2%;
      right: 0px;
    }
    .circle-2 {
      position: absolute;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      background: var(--v-primary-base);
      left: 10%;
      bottom: 20%;
      z-index: 0;
    }
  }
}
.section h2 {
  font-size: 2rem;
  text-transform: uppercase;
}
.stake-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.faq-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background: rgb(32, 31, 68);
  background: linear-gradient(
    326deg,
    rgba(32, 31, 68, 1) 0%,
    rgba(40, 37, 89, 1) 100%
  );
}
.faq-decoration {
  .circle {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 100%;
    background: var(--v-primary-base);
    bottom: 2%;
    right: 0px;
  }
  .circle-2 {
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: var(--v-primary-base);
    left: 10%;
    bottom: 20%;
    z-index: 0;
  }
}
.aboutus-section {
  background: rgb(32, 31, 68);
  background: linear-gradient(
    3deg,
    var(--v-backgroundDark-base) 0%,
    var(--v-backgroundMedium-base) 100%
  );
}
.calculator-section {
  border-top: rgba(0, 0, 0, 0.05) 5px solid;
  background: var(--v-backgroundDark-base);
  position: relative;
}
.calculator-decoration {
  div {
    width: 700px;
    height: 700px;
    border-radius: 100%;
    position: absolute;
    right: 0;
    top: 10%;

    background: radial-gradient(
      63.09% 102.35% at 63.08% -2.35%,
      var(--v-secondary-base) 0%,
      var(--v-backgroundDark-base) 100%
    );
    opacity: 0.1;
  }
  .circle-2 {
    position: absolute;
    width: 240px;
    height: 240px;
    border-radius: 100%;
    background: radial-gradient(
      63.09% 102.35% at 63.08% -2.35%,
      var(--v-primary-base) 0%,
      #441950 100%
    );
    left: 10%;
    top: 20%;
    z-index: 0;
  }
}
.blockchains-section {
  padding: 3rem 0;
  background: rgba(255, 255, 255, 0.98);
}
.blockchain-logo {
  max-width: 300px;
  height: 100%;
  margin: 1rem 0;
  padding: 0.5rem;
}
</style>
