import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        // Custom AltoStake colors
        primary: "#D2007E", // Fucsia
        secondary: "#86B9FF", // Celeste

        accentDark: "#9C6C2D",
        accentMedium: "#C19041",
        accentLight: "#D6A550",
        accentLighter: "#ECD377",

        background: "#0e0a19",

        backgroundDark: "#201F44",
        backgroundMedium: "#282559",
        backgroundLight: "#4B2579",
      },
      light: {
        primary: "#FFA726",
        secondary: "#29B6F6",
        anyColor: "#0000",
      },
    },
  },
});
