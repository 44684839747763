<template>
  <div>
    <section id="howitworks" class="howitworks-section py-12 px-4">
      <v-container class="small-container">
        <div class="banner">
          <v-row>
            <v-col cols="12" lg="6" class="d-flex flex-column justify-center">
              <h2>How it works</h2>
              <p>
                In a Proof-of-Stake network, just as in a Proof-of-Work network,
                the blockchain is maintained over a diverse and geographically
                distributed network of different computers or servers called
                “nodes”. The networks allow for a diverse set of entities to
                participate in their operation and earn rewards for doing so.
              </p>
            </v-col>
            <v-col cols="12" lg="6">
              <Lottie :loop="false" class="lottie" path="howitworks.json" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>

    <div class="section px-4">
      <v-container class="small-container">
        <v-row class="d-flex align-center">
          <v-col cols="12" lg="6">
            <img
              style="max-width: 100%"
              src="../../assets/img/validators.svg"
              alt=""
            />
          </v-col>
          <v-col cols="12" lg="6">
            <h2>Validators</h2>
            <p>
              Validators are special nodes responsible for the provision of
              infrastructure as well as proposing and validating new blocks and
              appending them to the blockchain. Hence, together with other
              validators, they ensure the blockchains' security by monitoring
              its accuracy, establishing validity, guaranteeing availability,
              and provisioning the infrastructure for it to run on.
            </p>
            <p>
              For their work, validators are rewarded in the form of block
              rewards & transaction fees. In order to participate in securing
              the network and to be paid for this service, validators are
              required to lock up collateral “stake” which can be forfeited
              (i.e. “slashed”) programmatically if their actions break the
              programmatic rules that define the blockchain protocol which they
              secure.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="section px-4">
      <v-container class="small-container">
        <v-row class="d-flex align-center justify-center">
          <v-col cols="12" lg="6">
            <h2>Delegators</h2>
            <p>
              The right to validate and add blocks, hence, to secure the
              validity of the blockchain, is attached to every PoS token. Token
              holders who do not want to act as a validator, but still want to
              contribute to the blockchain’s security and earn rewards, can
              delegate the rights contained in their tokens to a validator of
              their choice. These token holders are called delegators and can be
              considered as a validators' customer.
            </p>
            <p>
              The amount of rewards a delegator can earn through the services of
              a validator is a function of the validators' total rewards and the
              ratio between the size of the holder’s own stake and the total
              stake of the validator. For their services, the validators charge
              a fee on the token holder’s rewards.
            </p>
          </v-col>
          <v-col class="pa-8" cols="12" lg="6">
            <img
              style="max-width: 100%"
              src="../../assets/img/delegators.svg"
              alt=""
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Lottie from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
  components: {
    Lottie,
  },
};
</script>

<style lang="scss" scoped>
.section {
  background: white;
  color: black;
  padding: 3rem 0;
}
.howitworks-section {
  min-height: 50vh;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--v-backgroundDark-base),
    var(--v-backgroundMedium-base)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--v-backgroundDark-base),
    var(--v-backgroundMedium-base)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.banner {
  min-height: 50vh;
  display: grid;
  place-items: center;
}
.howitworks-section h2 {
  font-size: 2rem;
  text-transform: uppercase;
}
</style>
