import ajax from "@/services/ajax";

export const getValidatorData = (validatorAddress) => {
  return ajax
    .get(
      `https://rest-fetchhub.fetch.ai:443/cosmos/staking/v1beta1/delegators/${validatorAddress}/validators`
    )
    .then((res) => {
      return res.data.validators[0];
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getFETData = () => {
    return ajax
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=fetch-ai&vs_currencies=usd`
      )
      .then((res) => {
        return res.data["fetch-ai"].usd;
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  