<template>
  <v-app>
    <Navbar />

    <v-main>
      <router-view />
    </v-main>

    <Footer />

    <v-fade-transition>
      <div v-if="windowTop > 100" @click="scrollToTop()" class="scroll-to-top">
        <v-icon size="36">mdi-chevron-up</v-icon>
      </div>
    </v-fade-transition>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  metaInfo: {
    title: "AltoStake",
    titleTemplate: "%s | Home",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Altostake validation and blockchain solutions, stake safely with us and earn as delegator to our validators.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      windowTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.$store.dispatch("FETCH_VALIDATOR_DATA");
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.windowTop =
        window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  background-color: var(--v-primary-base);
  border-radius: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
  bottom: 2rem;
  right: 2rem;
}
</style>
