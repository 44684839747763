<template>
  <section id="aboutus" class="section aboutus-section py-12 px-4">
    <v-container class="small-container">
      <v-row class="d-flex align-center py-12">
        <v-col cols="12" lg="12" md="12" sm="12">
          <h2>{{ $t("about-us.title") }}</h2>
          <p class="about-us-text">
            {{ $t("about-us.text") }}
          </p>
        </v-col>
      </v-row>
      <!-- <h2 class="text-center pb-8">{{ $t("about-us.title") }}</h2> -->
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .about-us-text {
    font-size: 1.5rem;
  }
}
.lottie {
  max-height: 500px;
  max-width: 100%;
}
</style>
