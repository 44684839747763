<template>
  <div>
    <v-app-bar app fixed color="backgroundMedium" elevate-on-scroll>
      <v-container class="d-flex align-center">
        <router-link class="d-flex align-center" to="/">
          <img class="logo" src="../assets/img/logo.png" alt="" />
          <h2 class="logo-text">AltoStake</h2>
        </router-link>
        <v-spacer></v-spacer>
        <router-link to="/stake"> </router-link>

        <div v-if="!mobile">
          <nav>
            <ul class="nav-list">
              <li
                class="nav-items"
                v-for="(item, i) in navigationItems"
                :key="i"
              >
                <router-link :to="item.to">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>

        <v-btn v-if="!mobile" class="mx-4" to="/stake" color="primary"
          >Stake now</v-btn
        >
        <!-- <v-select
          :items="langItems"
          @change="onChange"
          v-model="$i18n.locale"
          hide-details
          class="language-selector"
          item-value="value"
          solo
          background-color="backgroundDark"
          dense
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.value }}
          </template>
        </v-select> -->
        <v-btn icon v-if="mobile" text @click="drawer = !drawer">
          <v-icon> mdi-menu </v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      class="backgroundDark"
      v-model="drawer"
      temporary
      fixed
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Menu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in navigationItems"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-spacer class="my-4"></v-spacer>
      <v-btn block to="/stake" color="primary">Stake now</v-btn>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      navigationItems: [
        {
          name: "Home",
          to: "/",
        },
        {
          name: "About Us",
          to: "/#aboutus",
        },
        {
          name: "Security",
          to: "/#security",
        },
        {
          name: "How it works",
          to: "/#howitworks",
        },
      ],
      langItems: [
        { text: "English", value: "en" },
        { text: "Español", value: "es" },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm | this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    onChange(locale) {
      localStorage.setItem("lang", locale);
    },
  },
  created() {
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang;
      localStorage.setItem("lang", this.$route.query.lang);
    }
  },
};
</script>

<style scoped>
.v-application a {
  text-decoration: none !important;
}
.logo {
  max-height: 30px;
  margin-right: 10px;
}
.logo-text {
  text-decoration: none !important;
  color: white;
}
.language-selector {
  max-width: 70px;
  text-transform: uppercase;
}
.v-select__slot {
  display: none;
}
.v-input__icon {
  min-width: 12px !important;
  width: 12px !important;
}
.nav-list {
  display: flex;
  list-style: none;
}
.nav-items a {
  padding: 1rem;
  text-transform: uppercase;
  color: white !important;
}
</style>
