<template>
  <div>
    <v-footer class="footer backgroundDark py-12">
      <v-container class="small-container">
        <v-row>
          <v-col cols="6" lg="2" md="4" sm="6">
            <v-img style="max-width: 60px" src="../assets/img/logo.png"></v-img>
            <h3 class="mt-2">AltoStake.io</h3>
            <h6 class="text-uppercase">Secure validation services</h6>
          </v-col>
          <v-col>
            <h3>Links</h3>
            <ul class="links">
              <a href="/"> <li>Home</li></a>
              <a href="/#aboutus"> <li>About Us</li></a>
              <a href="/#security"> <li>Security</li></a>
              <a href="/#howitworks"> <li>How it works</li></a>
              <a href="/stake"> <li>Stake now</li></a>
            </ul>
          </v-col>
          <v-col cols="6" lg="2" md="4" sm="6">
            <h3>Online support <span style="font-size: 12px">via</span></h3>
            <div class="d-flex align-center align-center mt-2">
              <v-btn
                large
                color="#0396DE"
                href="https://t.me/altostake"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style="height: 18px"
                  class="mr-2"
                  src="../assets/img/telegram-logo.png"
                  alt=""
                />
                Telegram
              </v-btn>
            </div>
          </v-col>
          <v-col>
            <h3>Announcements <span style="font-size: 12px">via</span></h3>
            <div class="d-flex align-center align-center mt-2">
              <v-btn
                color="#00acee"
                class="ml-2"
                href="https://twitter.com/altostake"
                large
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style="height: 18px"
                  class="mr-2"
                  src="../assets/img/twitter-logo.png"
                  alt=""
                />
                Twitter
              </v-btn>
            </div>
          </v-col>
          <!-- <v-col>
          <h3>Powered by</h3>
          <img
            style="max-width: 150px"
            class="mt-2"
            src="../assets/img/fetch-logo-white.png"
            alt=""
          />
        </v-col> -->
        </v-row>
      </v-container>
    </v-footer>
    <div class="d-flex justify-center py-1 background">
      <small>Copyright 2024 | Altostake</small>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding: 2rem 0;
}
.links a {
  color: white !important;
}
</style>
