import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/stake",
    name: "Stake",
    component: () => import("../views/Stake.vue"),
  },
  {
    path: "/howitworks",
    name: "How It Works",
    component: () => import("../views/HowItWorks.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/common/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        path: "/",
        selector: to.hash,
      };
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
