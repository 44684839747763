import axios from "axios"

const ajax = axios.create();

// ajax.interceptors.response.use(
//   response => {
//     const statusCode = get(response, "data.statusCode");

//     if (statusCode === 500) {
//       if (router.currentRoute.name !== errorPageName) {
//         router.push("/error");
//       }

//       const message = get(response, "data.message");

//       report(new Error(message));

//       return Promise.reject(message);
//     }

//     return response;
//   },
//   error => {
//     if (navigator.onLine && router.currentRoute.name !== errorPageName) {
//       router.push("/error");
//     }

//     report(error);

//     return Promise.reject(error);
//   }
// );

export default ajax;
