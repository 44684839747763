<template>
  <div class="backgroundDark">
    <v-container>
      <v-row class="banner-section">
        <v-col cols="12" lg="6" order-lg="first" order="last">
          <Lottie path="data.json" />
        </v-col>
        <v-col cols="12" lg="6" class="d-flex align-center">
          <div class="banner-text">
            <h2>{{ $t("home.banner.title-one") }}.</h2>
            <h2>{{ $t("home.banner.title-two") }}.</h2>
            <h2 class="mb-2">{{ $t("home.banner.title-three") }}</h2>

            <div class="my-4">
              <p>
                {{ $t("home.banner.description-one") }}
                <span>{{ $t("home.banner.description-two") }}</span>
              </p>
              <p></p>
            </div>
            <!-- <div class="d-flex flex-wrap" v-if="fetch.price">
              <div class="flex-shrink-1">
                <div class="grey darken-4 pa-8">
                  <h3 class="mb-2">FET Price:</h3>
                  <p class="staked">$ {{ fetch.price }}</p>
                </div>
              </div>
              <div class="flex-grow-1">
                <div class="backgroundMedium pa-8">
                  <h3 class="mb-2">FET Staked:</h3>
                  <p class="staked">{{ formatNumber(fetch.staked, 0) }}</p>
                </div>
              </div>
              <div class="flex-grow-1">
                <div class="backgroundMedium darken-1 pa-8">
                  <h3 class="mb-2">USD Stake Worth:</h3>
                  <p class="staked">
                    $ {{ formatNumber(fetch.staked * fetch.price) }} USD
                  </p>
                </div>
              </div>
            </div> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Lottie from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { formatNumber } from "../../utils/numbers";
import { mapState } from "vuex";

export default {
  components: {
    Lottie,
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    ...mapState({
      fetch: (state) => state.fetch,
    }),
  },
  methods: {
    formatNumber,
  },
};
</script>

<style lang="scss" scoped>
.banner-section {
  padding: 3rem 0;
  min-height: 80vh;
}
.banner-text h2 {
  font-size: 3.5rem;
  text-transform: uppercase;
  line-height: 3.5rem;
}
.banner-text p {
  font-size: 1.5rem;
  margin: 0;
}
@media (max-width: 768px) {
  .banner-text {
    padding: 1.5rem;
  }
  .banner-text h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
}

@media (max-width: 400px) {
  .banner-text h2 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .banner-text p {
    font-size: 1rem;
    margin: 0;
  }
}

.stake-data {
  min-width: 200px;
  padding: 1rem;
  background: var(--v-backgroundLight-base);
}
.staked {
  font-size: 3rem;
}
</style>
